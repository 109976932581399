import React from 'react';
import { motion } from 'framer-motion';
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.module.css';

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={
      <>
        {header}
        <FontAwesomeIcon
          icon={faAngleDoubleDown}
          className={styles.chevron}
          src={''}
          alt='Chevron Down'
        />
      </>
    }
    className={styles.item}
    buttonProps={{
      className: ({ isEnter }) =>
        `${styles.itemBtn} ${isEnter && styles.itemBtnExpanded}`,
    }}
    contentProps={{ className: styles.itemContent }}
    panelProps={{ className: styles.itemPanel }}
  />
);

export default function FAQ2() {
  return (
    <div className={styles.faqSection} id='faq'>
      <h2 className='titleAbout'>
        <div className='title-bg'>Faq</div>
        <span className='word'>Faq</span>
      </h2>
      <div className={styles.app}>
        <Accordion transition transitionTimeout={200} allowMultiple>
          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header='How long does the course lasts?'
              className={styles.faqTitle}
            >
              <p>
                There is no time limit, you learn in your own time, whenever you
                want.
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header='Do I need to know how to read sheet music?'
              className={styles.faqTitle}
            >
              <p>
                No, the main thing is to be able to learn by looking and
                listening, but we also offer sheet music and tablature.
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header=' Do I need to know how to play the guitar before starting the lessons'
              className={styles.faqTitle}
            >
              <p>
                Yes, the course is for people who already know at least the
                basics to play some songs, but it doesn't need to be advanced.
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header="I believe I'm in a beginner level, by which lesson should I start?"
              className={styles.faqTitle}
            >
              <p>
                There is no rule for that. Here you will find the beginner,
                intermediate and advanced levels and you can start by any lesson
                you feel comfortable to play.
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header='What equipment should I have to study with you?'
              className={styles.faqTitle}
            >
              <p>
                Just a 6-string guitar (or 7-string) and your computer or cell
                phone with internet access.
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header='What do I get when I subscribe?'
              className={styles.faqTitle}
            >
              <p>
                Within your membership period, you get instant access to our
                complete lesson library. Beside the vídeos each lesson includes
                sheet music, tabs and play alongs.
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header="What if I don't like the course?"
              className={styles.faqTitle}
            >
              <p>
                You will have a 7-day guarantee to test the site and get all
                your money back in case you don't like
              </p>
            </AccordionItem>
          </motion.div>

          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header='How can I cancel my membership?'
              className={styles.faqTitle}
            >
              <p>
                You can cancel your membership at anytime! After cancelation you
                can access the content for the rest of your membership duration.
              </p>
            </AccordionItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header="I didn't use my membership, can I receive a refund?"
              className={styles.faqTitle}
            >
              <p>
                There will be no refunds for those who are not using their
                membership. You are still charged untill you request a
                cancellation
              </p>
            </AccordionItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header='Why should I trust Rocho as a teacher?'
              className={styles.faqTitle}
            >
              <p>
                Rocho has more than 20 years teaching and developing his skills
                as a teacher to make it as natural as possible. He is also a
                very experient instrumentalist and has already accompained great
                names of music worldwide.
              </p>
            </AccordionItem>
          </motion.div>
          <motion.div
            initial={{ opacity: 0.0 }}
            transition={{ ease: 'easeOut', duration: 1.5 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
          >
            <AccordionItem
              header=' Will this course make me ready to play professionally?'
              className={styles.faqTitle}
            >
              <p>
                Here you will find a lot of valuable information that can
                prepare you for the professional market, but nothing replaces
                your hours of study and your practical experiences playing with
                other musicians.
              </p>
            </AccordionItem>
          </motion.div>
        </Accordion>
      </div>
    </div>
  );
}
