import { useRef, useState } from 'react';
import { Navigation, Pagination, A11y } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import SwiperNavButtons from './SwiperNavButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useInView } from 'framer-motion';
import {
  faCirclePlay,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

import './courses.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Courses() {
  return (
    <div className='courses-container'>
      <h2 className='titleAbout'>
        <div className='title-bg'>OUR COURSES</div>
        <span className='word'>Our</span>
        <span className='word usTitle'>COURSES</span>
      </h2>
      <div className='btns-container'>
        <button className='slider-btn-prev'>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>

        <button className='slider-btn-next'>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>

      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={50}
        slidesPerView={3}
        /*using the refs instead of className*/
        navigation={{
          prevEl: '.slider-btn-prev',
          nextEl: '.slider-btn-next',
        }}
        pagination={{ clickable: true }}
        loop={true}
        draggable={true}
        speed={500}
        autoplay={{
          delay: 5000,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 3,
          },
        }}
      >
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/7stringbasic.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>7-Strings Guitar Language</p>
              <p className='course-subtitle'>Beginner</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/7stringintermediate.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>7-Strings Guitar Language</p>
              <p className='course-subtitle'>Intermediate</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/7stringadvanced.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>7-Strings Guitar Language</p>
              <p className='course-subtitle'>Advanced</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/7stringrepertoriebasic.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>7-Strings Guitar Repertoire</p>
              <p className='course-subtitle'>Beginner</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/7stringrepertorieintermediate.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>7-Strings Guitar Repertoire</p>
              <p className='course-subtitle'>Intermediate</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/7stringrepertorieadvanced.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>7-Strings Guitar Repertoire</p>
              <p className='course-subtitle'>Advanced</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/musictheory.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>Music Theory</p>
            </motion.div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='course-slide'>
          <a
            href='https://hotmart.com/en/marketplace/products/brazilian-guitar-academy/L61512821B'
            target='_blank'
            rel='noreferrer'
            style={{ height: 'min-content', margin: 'auto' }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
              viewport={{ once: true }}
            >
              <div className='course-slider__img-container'>
                <img
                  src={require('./images/bossanova.webp')}
                  className='course-slide__img'
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className='course-slide__icon fa-solid'
                />
              </div>
              <p className='course-title'>Bossa Nova</p>
            </motion.div>
          </a>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
