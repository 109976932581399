import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';
import './ourTeacher.css';

export default function OurTeacher() {
  const [showText, setShowText] = useState('0fr');
  const [buttonText, setButtonText] = useState('see more');
  const hideStyle = {
    display: 'grid',
    gridTemplateRows: showText,
    transition: '500ms',
  };

  function handleButtonClick() {
    if (showText === '0fr') {
      setShowText('1fr');
      return setButtonText('see less');
    } else {
      setShowText('0fr');
      return setButtonText('see more');
    }
  }

  return (
    <div className='ourteacher-section'>
      <h2 className='titleAbout'>
        <div className='title-bg'>OUR TEACHER</div>
        <span className='word'>Our</span>
        <span className='word usTitle'>Teacher</span>
      </h2>
      <div className='ourteacher-container'>
        <motion.img
          src={require('./images/daninobg.webp')}
          className='ourteacher-bg'
          alt='Rocho'
          style={{ alignSelf: 'self-end' }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ type: 'spring', duration: 2 }}
          viewport={{ once: true }}
        />
        <motion.div
          className='ourteacher-txt-container'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ type: 'spring', duration: 2 }}
          viewport={{ once: true }}
        >
          <div className='ourteacher-txt'>
            Rocho is a 7 stringed guitar player, born in Bahia, Brazil, and
            graduated by the Federal University of Bahia (UFBA) in classical
            guitar. Starting in music at the age of ten, he's got his first
            guitar after seing Jimi Hendrix on TV. This way he started playing
            blues and rock, then passed to heavy metal, fusion and finally got
            to the university, where he had his first real contact with the
            acoustic guitar.
            <div className='hide-txt' style={hideStyle}>
              <div style={{ overflow: 'hidden' }}>
                The brazilian repertoire for classical guitar is based on the
                rhythms that originated samba and choro, from composers like
                Villa Lobos, Garoto, and João Pernambuco, and this way Rocho
                could learn brazilian music from its beginning. During his
                graduation he fell in love for the 7 stringed guitar style and
                dove deep into the choro genre, where he could develop his
                harmonic knowledge, improvisation, repertoire and rhythm. Rocho
                was able to share the stage with great names of brazilian music
                like Ivete Sangalo, Carlinhos Borwn, Alcione, Zelia Ducan,
                Daniela Mercury, Armandinho Macedo and many others. He was also
                a member of the contemporaneous choro group Patuscada, with wich
                he toured in Europe and played at the Sommersprossen Musik
                Festival in German.
              </div>
            </div>
          </div>
          <button onClick={handleButtonClick}>{buttonText}</button>
        </motion.div>
      </div>
    </div>
  );
}
