import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from 'react-scroll/modules/components/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faWhatsapp,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion';

const HeaderContainer = styled.header`
  display: flex;
  background-color: white;
  justify-content: space-between;
  padding: 0.5em 2em 0.5em 2em;
  height: 65px;
  position: relative;
  gap: 10px;
  @media (max-width: 750px) {
    padding: 10px;
  }
  @media (max-width: 360px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 10px 0 10px;
    grid-auto-flow: column;
    display: grid;
  }
`;

const HamburgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;

const HeaderLinksMobile = styled.div`
  width: 100vw;
  max-height: ${(props) => props.maxHeight + 'px'};
  flex-direction: column;
  transition: 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 1);
  position: absolute;
  z-index: -1;
  left: 0;
  top: 60px;
  display: flex;
  padding: 0;
  gap: 0;
  overflow: hidden;
  @media (max-width: 650px) {
    max-height: ${(props) => props.maxHeight + 'vh'};
    height: 100vh;
  }
`;

const HamburgerIconsDiv = styled.div`
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 25px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  box-shadow: none;
`;

const Social = styled.div`
  display: flex;
  gap: 15px;
`;

const Button = styled.button`
  & {
    height: min-content;
    margin-top: auto;
    margin-bottom: auto;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 5px;
    display: block;
    border: 0px;
    font-weight: 700;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(
      45deg,
      rgb(46, 164, 79) 25%,
      rgb(80, 200, 100) 51%,
      rgb(46, 164, 79) 100%
    );
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    transform: scale(1.00001);
  }

  &:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 768px) {
    font-size: 11px;
    padding: 15px 0;
    width: 100%;
    max-width: 90px;
  }
`;

const HeaderRight = styled.div`
  & {
    display: flex;
    align-items: center;
    gap: 25px;
  }

  @media (max-width: 650px) {
    gap: 15px;
    justify-content: space-between;
  }
  @media (max-width: 360px) {
    gap: 0;
    justify-content: space-between;
  }
`;

export default function Header() {
  const [maxHeight, setMaxHeight] = useState(0);
  const [transformBottom, setTransformBottom] = useState({
    transform: 'none',
  });
  const [transformMiddle, setTransforMiddle] = useState({
    opacity: 1,
  });
  const [transformTop, setTransformTop] = useState({
    transform: 'none',
  });

  return (
    <Container>
      <HeaderContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img className='header-img' src={require('./images/logo.webp')} />
        </div>
        <a
          href='https://pay.hotmart.com/L61512821B'
          target='_blank'
          rel='noreferrer'
          style={{ height: 'min-content', margin: 'auto' }}
          className='btn-container'
        >
          <Button>Sign Up</Button>
        </a>
        <HeaderRight>
          <a
            href='https://www.youtube.com/@violaosetecordas'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={faYoutube}
              style={{ fontSize: '20px', cursor: 'pointer' }}
              className='iconHeader'
            />
          </a>
          <a
            href='https://www.instagram.com/brazilianguitaracademy/?igshid=MzRlODBiNWFlZA%3D%3D'
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon
              icon={faInstagram}
              style={{ fontSize: '20px', cursor: 'pointer' }}
              className='iconHeader'
            />
          </a>

          <HamburgerContainer>
            <div
              onClick={() => {
                maxHeight === 0
                  ? setMaxHeight(window.innerWidth > 650 ? 250 : 100)
                  : setMaxHeight(0);
                transformBottom.transform === 'none'
                  ? setTransformBottom({
                      transform: `translate(0px, -10px) rotate(45deg)`,
                    })
                  : setTransformBottom({
                      transform: 'none',
                    });
                transformTop.transform === 'none'
                  ? setTransformTop({
                      transform: `translate(0px, 8px) rotate(-45deg)`,
                    })
                  : setTransformTop({
                      transform: 'none',
                    });
                transformMiddle.opacity === 1
                  ? setTransforMiddle({ opacity: 0 })
                  : setTransforMiddle({ opacity: 1 });
              }}
            >
              <HamburgerIconsDiv style={transformTop} />
              <HamburgerIconsDiv className='barTwo' style={transformMiddle} />
              <HamburgerIconsDiv className='barThree' style={transformBottom} />
            </div>

            <HeaderLinksMobile maxHeight={maxHeight}>
              <motion.div
                viewport={{ once: true }}
                className='header-link-container'
                initial={{ opacity: 0, y: 15 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', duration: 2 }}
              >
                <Link
                  to='about'
                  spy={true}
                  smooth={true}
                  offset={-65}
                  duration={500}
                  className='headerLink firstLink'
                  onClick={() => setMaxHeight(0)}
                >
                  About Us
                </Link>
              </motion.div>
              <motion.div
                className='header-link-container'
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 15 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', duration: 2 }}
              >
                <Link
                  to='whyUs'
                  spy={true}
                  smooth={true}
                  offset={-65}
                  duration={500}
                  className='headerLink'
                  onClick={() => setMaxHeight(0)}
                >
                  Why Us
                </Link>
              </motion.div>
              <motion.div
                className='header-link-container'
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 15 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', duration: 2 }}
              >
                <Link
                  to='faq'
                  spy={true}
                  smooth={true}
                  offset={-65}
                  duration={500}
                  className='headerLink lastLink'
                  onClick={() => setMaxHeight(0)}
                >
                  FAQ
                </Link>
              </motion.div>
              <motion.div
                className='header-link-container'
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 15 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', duration: 2 }}
              >
                <Link
                  to='testimonies'
                  spy={true}
                  smooth={true}
                  offset={-65}
                  duration={500}
                  className='headerLink'
                  onClick={() => setMaxHeight(0)}
                >
                  Testimonies
                </Link>
              </motion.div>
              <motion.div
                className='header-link-container'
                viewport={{ once: true }}
                initial={{ opacity: 0, y: 15 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', duration: 2 }}
              >
                <Link
                  to='contactUs'
                  spy={true}
                  smooth={true}
                  offset={-65}
                  duration={500}
                  className='headerLink'
                  onClick={() => setMaxHeight(0)}
                >
                  Contact
                </Link>
              </motion.div>
            </HeaderLinksMobile>
          </HamburgerContainer>
        </HeaderRight>
      </HeaderContainer>
    </Container>
  );
}
