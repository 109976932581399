import React from "react";
import styled from "styled-components";

import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faUserClock,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const AdvantageCardsContainer = styled.div`
  min-height: 35vh;
  max-width: 900px;
  display: flex;
  justify-content: center;
  gap: 35px;
  flex-wrap: wrap;
  text-align: center;
`;

const AdvantageCardTitle = styled.h4`
  font-size: 22px;
`;

const CardDescription = styled.p``;

const AdvantagesSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(128, 128, 128, 0.18);
  gap: 25px;
  padding: 2em;

`;

export default function About() {
  return (
    <AdvantagesSection id='whyUs'>
      <h1 className='titleAbout'>
        <div className='title-bg'>wHY uS</div>
        <span className='word'>Why</span>
        <span className='word'>Us</span>
      </h1>
      <AdvantageCardsContainer>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <motion.div>
              <FontAwesomeIcon icon={faCheck} className='cardIcon' />{" "}
            </motion.div>
          </div>
          <AdvantageCardTitle>Downloads</AdvantageCardTitle>
          <CardDescription>Tabs, sheetmusic and playalongs.</CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>In your hands</AdvantageCardTitle>
          <CardDescription>Watch it on line anytime, anywhere.</CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>Repertoire</AdvantageCardTitle>
          <CardDescription>
            Classics from samba, choro, bossa nova and more.
          </CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>7-string guitar</AdvantageCardTitle>
          <CardDescription>
            Exclusive lessons adapted for 6-string.
          </CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>More than 150 Lessons</AdvantageCardTitle>
          <CardDescription>More than 10 hours of content.</CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>Sound Brazilian</AdvantageCardTitle>
          <CardDescription>
            Focus on absorbing the brazilian accent.
          </CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>Music Theory</AdvantageCardTitle>
          <CardDescription>
            So you can understand what you´re playing and create your own lines.
          </CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>Rhythm</AdvantageCardTitle>
          <CardDescription>
            Many different ways to play the so many brazilian rhythms.
          </CardDescription>
        </motion.div>
        <motion.div
          initial={{ opacity: 0.0 }}
          transition={{ ease: "easeOut", duration: 1.5 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className='advantageCard'
        >
          <div className='card-icon-container'>
            <FontAwesomeIcon icon={faCheck} className='cardIcon' />
          </div>
          <AdvantageCardTitle>Satisfaction Guarantee</AdvantageCardTitle>
          <CardDescription>Love it or get your money back.</CardDescription>
        </motion.div>
      </AdvantageCardsContainer>
    </AdvantagesSection>
  );
}
