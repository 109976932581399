import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView } from 'framer-motion';

import YoutubeEmbed from './YoutubeEmbed';

export default function About() {
  const [showText, setShowText] = useState('0fr');
  const [buttonText, setButtonText] = useState('see more');

  const hideStyle = {
    display: 'grid',
    gridTemplateRows: showText,
    transition: '500ms',
  };

  function handleButtonClick() {
    if (showText === '0fr') {
      setShowText('1fr');
      return setButtonText('see less');
    } else {
      setShowText('0fr');
      return setButtonText('see more');
    }
  }

  return (
    <div id='about' className='aboutContainer'>
      <h2 className='titleAbout'>
        <div className='title-bg'>About Us</div>
        <span className='word'>About</span>
        <span className='word usTitle'>Us</span>
      </h2>
      <div className='aboutContent'>
        <div className='insideBox'>
          <div className='aboutText'>
            <div className='about-txt-container'>
              The Brazilian Guitar Academy is your portal on the internet for
              the brazilian guitar secrets. Here you'll find different courses
              for the different styles and musical genres played in Brazil.{' '}
              <b>
                Bossa Nova, Samba accompainment, 7-stringed guitar and music
                theory
              </b>
              &nbsp;are some of the courses you'll find as soons as you join
              this family.
              <div className='hide-txt' style={hideStyle}>
                <div style={{ overflow: 'hidden' }}>
                  Every course comes with rhythm lessons for you to learn the
                  different ways to play samba, bossa nova, forró, and all the
                  genres that we are going to play. Talking about brazilian
                  music is talking about rhythm and that's a subject that the
                  Brazilian Guitar Academy handles very carefully. It is
                  important that you sound like a brazilian guitar player. By
                  the way, all the 7 Stringed guitar courses are adapted for 6
                  stringed guitar. Watch it anywhere, anytime, in any order!
                  Welcome to the Brazilian Guitar Academy!
                </div>
              </div>
            </div>

            <button onClick={handleButtonClick}>{buttonText}</button>
          </div>
          <div className='introVideoContainer'>
            <YoutubeEmbed />
          </div>
        </div>
      </div>
    </div>
  );
}
