import React from "react";
import PropTypes from "prop-types";

import "./youtubeEmbed.css";

const YoutubeEmbed = () => (
  <div className='video-responsive'>
    <iframe
      width='853'
      height='480'
      src={`https://www.youtube.com/embed/7Y9S90kbdfo`}
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title="Rocho's introduction"
    />
  </div>
);

export default YoutubeEmbed;
