import React from "react";
import About from "./About";
import Cards from "./Cards";
import Form from "./Form";
import FAQ2 from "./FAQ2";
import Testimonials from "./Testimonials";
import OurTeacher from "./OurTeacher";
import Courses from "./Courses";

export default function Main() {
  return (
    <div>
      <About />
      <OurTeacher />
      <Courses />
      <Cards />
      <FAQ2 />

      <Testimonials />
      <Form />
    </div>
  );
}
