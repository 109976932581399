import React, { useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import TermsAndConditions from './TermsAndConditions';

const FooterContainer = styled.footer`
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background: #121212;
  padding: 0 2em;
  justify-content: space-around;
`;

const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Links = styled.div`
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-around;
  // gap: 20px;
`;

const LinksText = styled.div`
  cursor: pointer;
  transition: 0.25s ease-out;
  &:hover {
    transition: 0.25s ease-out;
    color: rgb(46, 164, 79);
  }
`;

export default function Footer() {
  const [termsStyle, setTermsStyle] = useState({
    opacity: 0,
    pointerEvents: 'none',
  });

  function toggleTermsAndConditions() {
    if (termsStyle.opacity === 0) {
      setTermsStyle({
        opacity: 1,
        pointerEvents: 'all',
      });
    } else {
      setTermsStyle({
        opacity: 0,
        pointerEvents: 'none',
      });
    }
  }

  return (
    <FooterContainer>
      <Social style={{ fontSize: '25px', color: '#ffffff' }}>
      <a
              href='https://www.youtube.com/@violaosetecordas'
              target='_blank'
              rel='noreferrer'
            >
          <FontAwesomeIcon icon={faYoutube}                 
          style={{ fontSize: '20px', cursor: 'pointer' }}
          className='iconHeader'/>
          </a>
        <a
          href='https://www.instagram.com/brazilianguitaracademy/?igshid=MzRlODBiNWFlZA%3D%3D'
          target='_blank'
          rel='noreferrer'
        >
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faInstagram}
            className='iconHeader'
          />
        </a>

      </Social>
      <Links style={{ color: '#ffffff' }}>
        <LinksText onClick={toggleTermsAndConditions}>
          Terms & Conditions
        </LinksText>
      </Links>
      <TermsAndConditions
        style={termsStyle}
        toggleTermsAndConditions={toggleTermsAndConditions}
      />
    </FooterContainer>
  );
}
