import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import './testimonials.css';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function Testimonials() {
  return (
    <div className='gray-bg'>
      <h1 className='titleAbout'>
        <div className='title-bg'>TESTIMONIALS</div>
        <span className='word'>Testimonials</span>
      </h1>
      <div className='slider-section-container'>
        <div className='slider-section'>
          <div className='sliderContainer' id='testimonies'>
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              navigation={true}
              pagination={true}
              speed={1500}
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              preventInteractionOnTransition={true}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 0,
                modifier: 0,
                slideShadows: true,
              }}
              autoplay={{
                delay: 5000,
              }}
              scrollbar={{ draggable: true }}
              className='mySwiper'
            >
              <SwiperSlide className='testimonySLider'>
                <div className='testimonyContainer'>
                  <img
                    src={require('./images/sara.webp')}
                    className='testimonyImage'
                    alt='Sara Abreu'
                  />

                  <p className='testimonialText'>
                    <i>
                      From Rocho I learned the language of the 7-string guitar
                      from scratch, as well as most of the choro and samba
                      repertoire that I play with his beautiful arrangements. In
                      addition to being a great teacher, he became a friend and
                      a great supporter of my career in music. I really admire
                      the great knowledge he has and I am very grateful for his
                      teachings and his generosity in passing on knowledge.
                    </i>
                  </p>
                  <p className='testimonialName'>Sara Abreu</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className='testimonySLider'>
                <div className='testimonyContainer'>
                  <img
                    src={require('./images/sami.webp')}
                    className='testimonyImage'
                    alt='Sami'
                  />

                  <p className='testimonialText'>
                    <i>
                      Music is not all about what notes are played and where you
                      play them on the guitar... it’s about how they are played,
                      for people, with people, and within the context of a
                      living breathing culture. In my 14 years of studying
                      guitar I have never met a teacher that understands this
                      more than Rocho. He gives every note and technique a
                      context that brings it to life. Along with this, he brings
                      a personality that inspires you to be the best person you
                      can be. I am so thankful for Rocho’s work! Without him
                      this (insert word for outsider) would be totally lost!
                      Thank you Rocho!!!
                    </i>
                  </p>
                  <p className='testimonialName'>Sami</p>
                </div>
              </SwiperSlide>
              <SwiperSlide className='testimonySLider'>
                <div className='testimonyContainer'>
                  <img
                    src={require('./images/bill.webp')}
                    className='testimonyImage'
                    alt='name of the first testimony'
                  />

                  <p className='testimonialText'>
                    <i>
                      Rocho helped familiarise me with the language of choro as
                      well as clean up bad habits that were holding me back and
                      slowing down my progression. Rocho identified weaknesses
                      in my playing and built exercises to correct these
                      deficiencies, which we later integrated into songs I was
                      learning. Rocho also helped me with understanding
                      functional harmony within a choro context. Without his
                      help, there is no way I’d have ever been able to play
                      choro!
                    </i>
                  </p>
                  <p className='testimonialName'>Bill Evans</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
